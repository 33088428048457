import { useEffect, useState } from 'react';
import axios from 'axios';

const useSeoData = (pageLink) => {
  const [seoData, setSeoData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await axios.get(`https://admin.sixdindia.com/seo_configuration_api.php?page_link=${pageLink}`);
        setSeoData(response.data);
      } catch (err) {
        console.error("Error fetching SEO data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSeoData();
  }, [pageLink]);

  return { seoData, loading, error };
};

export default useSeoData; 