import React from "react";
import SliderMe from "../components/SliderMe";
import ShareIcons from "../components/ShareIcons";
import { Link } from "react-router-dom";
import useSeoData from "../hooks/useSeoData";
import MetaTags from "../components/MetaTags";

const Inner14 = () => {
  const { seoData, loading, error } = useSeoData('/inner/14');
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading SEO data.</div>;
  return (
    <>
      <MetaTags
          title={seoData.meta_title || "Default Title"} 
          description={seoData.meta_description || "Default Description"} 
          robots={seoData.meta_robots || "Default Robots"} 
          canonical={seoData.canonical_link || "https://sixdindia.com/"} 
          schema={seoData.schema_code?.replaceAll('&quot;', '"') || "{}"}
          googleAnalyticsCode={seoData.google_analytics_code}
      />
      <section className="CaseInnerWrapper">
        <div className="CaseInnerContainer">
          <section className="InnerHero">
            <img
              src="/assets/7-8315eb1f.png"
              alt="Safe and Efficient HV Transformer Alignment with the "
              style={{ width: "100%" }}
            />
            <h2 className="InnerHeroH1 font-face-futur-md-bt">
              Safe and Efficient HV Transformer Alignment with the{" "}
              <span className="orange">GIS Floor</span>{" "}
            </h2>
            <div className="tagsD font-face-futur-HV" id="topGap">
            <Link to="/sectors/power" className="tags2">Power</Link>
            <Link to="/services/dimension-control" className="tags2">Dimension Control - DPM</Link>   
            </div>
            <div className="bar">
              <h2 className="InnerHeroH2 font-face-futur-md-bt">
                <div className="lineabc" />
                The Problem at Hand
              </h2>
              <p className="font-face-futur-BK innerPara">
                NTPC ventured into hydropower with this plant and faced a
                perplexing challenge in determining the accurate openings of
                High Voltage (HV) transformer bushing terminals with respect to
                Gas Insulated Substation (GIS) floor openings.
              </p>
            </div>
            <div className="why font-face-futur-md-bt">
              <h1>
                Why <span>SIXD?</span>
              </h1>
              <p className="whyPara font-face-futur-LT">
                &nbsp;SIXD is highly recognized by NTPC because of the special
                procedure we developed for their thermal power plants, DPM. As
                NTPC Hydro was looking for an expert partner who could provide
                them with the right and accurate solution, SIXD was recommended.
              </p>
            </div>
            <div className="section-inner">
              <div className="lineabc2" />
              <h1 className="section-heading-inner font-face-futur-BK">
                Scope of Work
              </h1>
              <div className="card-container-inner">
                <p className="font-face-futur-LT" />
                <div id="topGap2">
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMrSURBVHgB7Vg9dtpAEF6UBqpQQG2lAh5FuIFJl86+gblBuIHJDcgJIt/APoHFCSIK/rpNT4EroADyjd6sLVZCWgnp8V7s7z29HUmzu9+bnZ2dWSE+8I5QEjnAtu1qpVL5TPJsNvsrckYmku12++tut7uF2C2VSh20VU3F2+/3Ev8eN5vNk5RyJc5AKpLNZvMazQCTd037HA4HnyzEYVYrG5Hk5byH2D/qXCp5sKhrWZYEmRf+fMXWpccOkkUzmM/nDyIlEknCejaaZ0xsM7EVlnII8QETyri+rVaLXOIHnq54I0tEf4q8SOoEARdPL+2yYZw7jDEQbNm0RE+SpCUul8t/FMEsFtCI2hjrWRHFavQXi8Uvk77WqR/kg3kRJJBrYJxvEKU/sWUNeSNmI0khRrxtEvdcggpEFOTIT1VIGpj0iyTJG0OhJ3LEZDIZw6L++BTKTKwZIsmbpUsyBnOKOEHg6+SLxtaMsuSdEnhH5g7P84igw3N0aZPG6YdIBk4TrwgrKmCVHpUMy97E6UZZkk4K8ktXFAgQGysZhDtxukckO50OmV2ZXooCwUsuScbqmS83MpZXZTr+RPGQ3NpxSpa4LKomSkcktYTBFsXD5lbGKUVZUmoDFAKOx74lsXG8ON0QSXRwqYVP3opi8XrSYK5xnGKUJUfcVk0TgCwAsR6LEm7mxumGSCJ+UZBNlQCkhXb0ukn6IZIUv4IJQKPRuBH547cSTI7eyBAUTACQWjkoA65EToAV79XRS9mWydEbSZKsGfCZKi1JHkQDZQRBbrdbozz1ZDCfTqdPlJGTTBn6uUTZgo56Jyua1uOf4n4ul8tRrVbzfZPP1z691+t1iX8vwozcNfo4gZXxgffv+E7jjJPGMKq72QoD9c41tIvHQfgY6fpcxNGG62kXCVTnONpYvaRa3PgGQ6/2NEi+qVBZVEiHlpd8kJaYfdMxJZr6Logn6IlAwX9ycGRSuOFwqDLUd3Eaoplv1diyHQxOlaXN5S/FWIoMHtpx0kliSjSXq79zYEL04iQJGtHVer3+EgxPl056fZDlyIL8ujr3PrNQUOhKKm8/8N/jH3czjWCvn4QjAAAAAElFTkSuQmCC"
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMrSURBVHgB7Vg9dtpAEF6UBqpQQG2lAh5FuIFJl86+gblBuIHJDcgJIt/APoHFCSIK/rpNT4EroADyjd6sLVZCWgnp8V7s7z29HUmzu9+bnZ2dWSE+8I5QEjnAtu1qpVL5TPJsNvsrckYmku12++tut7uF2C2VSh20VU3F2+/3Ev8eN5vNk5RyJc5AKpLNZvMazQCTd037HA4HnyzEYVYrG5Hk5byH2D/qXCp5sKhrWZYEmRf+fMXWpccOkkUzmM/nDyIlEknCejaaZ0xsM7EVlnII8QETyri+rVaLXOIHnq54I0tEf4q8SOoEARdPL+2yYZw7jDEQbNm0RE+SpCUul8t/FMEsFtCI2hjrWRHFavQXi8Uvk77WqR/kg3kRJJBrYJxvEKU/sWUNeSNmI0khRrxtEvdcggpEFOTIT1VIGpj0iyTJG0OhJ3LEZDIZw6L++BTKTKwZIsmbpUsyBnOKOEHg6+SLxtaMsuSdEnhH5g7P84igw3N0aZPG6YdIBk4TrwgrKmCVHpUMy97E6UZZkk4K8ktXFAgQGysZhDtxukckO50OmV2ZXooCwUsuScbqmS83MpZXZTr+RPGQ3NpxSpa4LKomSkcktYTBFsXD5lbGKUVZUmoDFAKOx74lsXG8ON0QSXRwqYVP3opi8XrSYK5xnGKUJUfcVk0TgCwAsR6LEm7mxumGSCJ+UZBNlQCkhXb0ukn6IZIUv4IJQKPRuBH547cSTI7eyBAUTACQWjkoA65EToAV79XRS9mWydEbSZKsGfCZKi1JHkQDZQRBbrdbozz1ZDCfTqdPlJGTTBn6uUTZgo56Jyua1uOf4n4ul8tRrVbzfZPP1z691+t1iX8vwozcNfo4gZXxgffv+E7jjJPGMKq72QoD9c41tIvHQfgY6fpcxNGG62kXCVTnONpYvaRa3PgGQ6/2NEi+qVBZVEiHlpd8kJaYfdMxJZr6Logn6IlAwX9ycGRSuOFwqDLUd3Eaoplv1diyHQxOlaXN5S/FWIoMHtpx0kliSjSXq79zYEL04iQJGtHVer3+EgxPl056fZDlyIL8ujr3PrNQUOhKKm8/8N/jH3czjWCvn4QjAAAAAElFTkSuQmCC"
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Verify GIS floor markings accurately.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALoSURBVHgB7VhLctpAEG0RFixZeJ/JgipgJZ8gygnsI/gGrpzA+Ai+AdzAN4hyAosF/0XGe6rCioINpJ/Uo0yEwCoZhKj4VYkZtXpmmu7pzwzRB/5TOElCs9n8ys2d4zget3V5ioLebrea2+54PO7ZHz7ZLyzkAwvY5celSMAaFYs6r634ub26uqL5fP7TfHAsIcHwKyQ6zmKz2Tzzv1ugv2dST7ROzNcRGua4E1qXGy3zdYTmc+MLL6ymKNJiV/igIDxK+D3WbChsNZbYcW5MnxlcZnilA+A/FgqLH+Z9FFq4bYSlx3QfnVar1RGab3iZhrEKpjY0mSNWGH+75SYUtGILJ109Go0OCnlKsNCa/loi9o9YUDEDyWbOhVqt1pdFtNHKPmBrSddP+aylVYZQpSMiCALs5y9ZeCeTyRM3T5QR7xEU4cOzNLMXcDY4CiIK5URuQWUvfcvI+0jvRIUuBGUVNPR2xHFDKJ2gHF/vKQr6gG/oO3sUm56D7o8E2U/bZ0qpOoeke8RgO+blhYRIJa+6Uqk87xWUonzrJWh43xGUhXyR3EzHBNI28v1gMHjdKyiYWEOBTUsLQe12+4b5lIwJ7P2UF0g2mGu5XPa01v/MtyMoCxWwmd8MO8znGk0Oh8NrOjEuJjylmV6hLpVXnSxgz4U0Z1KmfgQajUZ/NpsFdGa8ZXpdrVZ/Uwmwo1FU4VmcqWhctDPV5Uhh085a9QNpzoT46NsEqfozFcSnQibTHztF5kGaRpEOv9uE1Wp19vCU5vULc8wtEy7a6z0uXrfow4nY7NfJSuYcOKhR5H2uOV0qAdKcKb4Loqgo8akESHMmfYzj7bFhmz7ch8c4+5wC9iWZcRiVZaBcEQKFxFj72tEsiFz/gHPSdDrtJweYkyd3cSVIyfPVqRDnRtd16+v1+oUyajQcHB0E3SIKltj0uInjRVGH6oxjcWL0iqqqUqsNNr1HkWY/7wyItNhH/i9DIvhAXvwBYuFJUcafED0AAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALoSURBVHgB7VhLctpAEG0RFixZeJ/JgipgJZ8gygnsI/gGrpzA+Ai+AdzAN4hyAosF/0XGe6rCioINpJ/Uo0yEwCoZhKj4VYkZtXpmmu7pzwzRB/5TOElCs9n8ys2d4zget3V5ioLebrea2+54PO7ZHz7ZLyzkAwvY5celSMAaFYs6r634ub26uqL5fP7TfHAsIcHwKyQ6zmKz2Tzzv1ugv2dST7ROzNcRGua4E1qXGy3zdYTmc+MLL6ymKNJiV/igIDxK+D3WbChsNZbYcW5MnxlcZnilA+A/FgqLH+Z9FFq4bYSlx3QfnVar1RGab3iZhrEKpjY0mSNWGH+75SYUtGILJ109Go0OCnlKsNCa/loi9o9YUDEDyWbOhVqt1pdFtNHKPmBrSddP+aylVYZQpSMiCALs5y9ZeCeTyRM3T5QR7xEU4cOzNLMXcDY4CiIK5URuQWUvfcvI+0jvRIUuBGUVNPR2xHFDKJ2gHF/vKQr6gG/oO3sUm56D7o8E2U/bZ0qpOoeke8RgO+blhYRIJa+6Uqk87xWUonzrJWh43xGUhXyR3EzHBNI28v1gMHjdKyiYWEOBTUsLQe12+4b5lIwJ7P2UF0g2mGu5XPa01v/MtyMoCxWwmd8MO8znGk0Oh8NrOjEuJjylmV6hLpVXnSxgz4U0Z1KmfgQajUZ/NpsFdGa8ZXpdrVZ/Uwmwo1FU4VmcqWhctDPV5Uhh085a9QNpzoT46NsEqfozFcSnQibTHztF5kGaRpEOv9uE1Wp19vCU5vULc8wtEy7a6z0uXrfow4nY7NfJSuYcOKhR5H2uOV0qAdKcKb4Loqgo8akESHMmfYzj7bFhmz7ch8c4+5wC9iWZcRiVZaBcEQKFxFj72tEsiFz/gHPSdDrtJweYkyd3cSVIyfPVqRDnRtd16+v1+oUyajQcHB0E3SIKltj0uInjRVGH6oxjcWL0iqqqUqsNNr1HkWY/7wyItNhH/i9DIvhAXvwBYuFJUcafED0AAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Measure the positions of HV transformer bushing
                        terminals in relation to the GIS floor's coordinate
                        system.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Establish a reliable axis on the GIS floor.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHbSURBVHgB7Zg/VsJAEMYnmCIlBb0pgUY8AXgDPIF4ArkBeAM5gXADvUGsbLUBUhlrKKSiAvzmvfBeHi8b2D9Zn7q/hll2WD52wszuEDn+KV7RZBiG1SAI7jzP62NYJUV2u91wPp/fkwZnool6vR76vv8KkV0MA9IAa3RqtRotl8sXUsQvmBvgC8LUjrArEUmSjQTsIX48qe5sbuh5N7HwB9sQOMbit6RAo9HgNcLse6qPQUXw/nnGnpAmaTQSttOdHZAkFbJDArFXpCHWllB+NrXEWhPK6Ii1KpRRFWtdKKMi1icLQEgrTwhnA8z1Up/CPGtFKGix2GNOLBa5dzybzT4P50oN/Xa7fSBDlLqjcRyP8DI65oeQ97Cbj0U+P/JnUsEJNY0Tahon1DS/RqhWwkeibtPBVaOABHW8lMtdIRB5g2oylvlMs9nsTqfTZ1Lg74ceYZxgV6VCiVNRQopoPaN8ACZLuPRkGifUNE6oaXKFbjab1d7GlfaCyqe9N9br9SrPQdhxzrQMvyC2j3IZUQlgbb7YDdPhG4rCZZ6fMOFzExaLPMGsytZ0GbB2dtgV+Qlb44vFIkY7O0kbB8r9+xPhFs81Kt27yMGjE0BN79DpxzlZ+PgXkcMy3//ktfs7vqUVAAAAAElFTkSuQmCC"
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHbSURBVHgB7Zg/VsJAEMYnmCIlBb0pgUY8AXgDPIF4ArkBeAM5gXADvUGsbLUBUhlrKKSiAvzmvfBeHi8b2D9Zn7q/hll2WD52wszuEDn+KV7RZBiG1SAI7jzP62NYJUV2u91wPp/fkwZnool6vR76vv8KkV0MA9IAa3RqtRotl8sXUsQvmBvgC8LUjrArEUmSjQTsIX48qe5sbuh5N7HwB9sQOMbit6RAo9HgNcLse6qPQUXw/nnGnpAmaTQSttOdHZAkFbJDArFXpCHWllB+NrXEWhPK6Ii1KpRRFWtdKKMi1icLQEgrTwhnA8z1Up/CPGtFKGix2GNOLBa5dzybzT4P50oN/Xa7fSBDlLqjcRyP8DI65oeQ97Cbj0U+P/JnUsEJNY0Tahon1DS/RqhWwkeibtPBVaOABHW8lMtdIRB5g2oylvlMs9nsTqfTZ1Lg74ceYZxgV6VCiVNRQopoPaN8ACZLuPRkGifUNE6oaXKFbjab1d7GlfaCyqe9N9br9SrPQdhxzrQMvyC2j3IZUQlgbb7YDdPhG4rCZZ6fMOFzExaLPMGsytZ0GbB2dtgV+Qlb44vFIkY7O0kbB8r9+xPhFs81Kt27yMGjE0BN79DpxzlZ+PgXkcMy3//ktfs7vqUVAAAAAElFTkSuQmCC"
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Provide real-time reports with deviation analysis
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="howwas">
              <div className="how-cnt">
                <span className="deco2" />
                <h1 className="font-face-futur-BK howh1">
                  How was the problem tackled
                </h1>
                <ul className="font-face-futur-LT howp">
                  <li>
                    The top experts at SIXD with the latest systems were
                    deployed at the site to understand the customer’s
                    requirements and provide the solution with precise 3D
                    measurements.
                  </li>
                  <li>
                    <br />
                    The provided data helped NTPC Tapovan accurately calculate
                    the required material for the connectivity of transformer
                    terminals.
                  </li>
                </ul>
              </div>
              <div className="how-img">
                <img src="/assets/t7-904357a0.png" />
              </div>
            </div>
            <div className="page">
              <img src="/assets/p7-0958af75.png" className="page" />
            </div>
            <div className="arch">
              <div className="fxm">
                <span className="deco3" />
                <h1 className="font-face-futur-md-bt">
                  The <span className="orange">Difference</span> Achieved
                </h1>
              </div>
              <div className="side-data">
                <div className="side-image">
                  <img src="/assets/i1-a28f622a.gif" alt="Animated Frame" />
                </div>
                <div className="side-col">
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">
                        Accuracy and Quality
                      </h1>
                      <p className="font-face-futur-LT">
                        NTPC Hydro achieved unparalleled accuracy in their
                        measurements, ensuring the highest quality in their
                        operations.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">
                        Real-Time Reporting
                      </h1>
                      <p className="font-face-futur-LT">
                        Real-time reports enabled immediate action and quality
                        control, optimizing operational efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Deviation Analysis</h1>
                      <p className="font-face-futur-LT">
                        We provided deviation results in all three dimensions,
                        empowering NTPC Hydro to make informed decisions
                        regarding equipment usage and maintenance.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <h1 className="font-face-futur-BK" />
                      <p className="font-face-futur-LT" />
                    </div>
                  </div>
                  <p className="p-side font-face-futur-LT">
                    In conclusion, SixD's innovative approach and advanced
                    technology resolved NTPC Hydro's challenging measurement
                    problem, enhancing the precision, efficiency, and safety of
                    their power generation operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="sharemy">
              <div className="sharex">
                <h1 className="font-face-futur-md-bt">Share this Case Study</h1>
                <div className="shareb">
                  <div className="share-50">
                    <div className="shareby font-face-futur-md-bt">
                      Share via
                    </div>
                    <ShareIcons />
                  </div>
                </div>
              </div>
            </div>
            <div className="innnercards">
              <h1 className="font-face-futur-BK">View more</h1>
              <div className="wrappermax">
                <Link to="/inner/10">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Contributing to Efficient Power Generation with Hydropower{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Dimension Control - DPM</p>
                    </div>
                    <img src="/assets/id10-cb721387.png" />
                  </div>
                </Link>
                <Link to="/inner/9">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Reducing Operational Costs and Improving Quality with Tata
                      Steel{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Steel</p>
                      <p>Dimension Control</p>
                    </div>
                    <img src="/assets/id9-5973ffa9.png" />
                  </div>
                </Link>
                <Link to="/inner/17">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      End-to-End Owner’s Engineering Solutions Form Power Plant
                      Excellence{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Owner’s Engineering</p>
                    </div>
                    <img src="/assets/id4c2-972761eb.png" />
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <SliderMe></SliderMe>
    </>
  );
};

export default Inner14;
