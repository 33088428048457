import React from "react";
import SliderMe from "../components/SliderMe";
import ShareIcons from "../components/ShareIcons";
import { Link } from "react-router-dom";
import useSeoData from "../hooks/useSeoData";
import MetaTags from "../components/MetaTags";

const Inner19 = () => {
  const { seoData, loading, error } = useSeoData('/inner/19');
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading SEO data.</div>;
  return (
    <>
      <MetaTags
          title={seoData.meta_title || "Default Title"} 
          description={seoData.meta_description || "Default Description"} 
          robots={seoData.meta_robots || "Default Robots"} 
          canonical={seoData.canonical_link || "https://sixdindia.com/"} 
          schema={seoData.schema_code?.replaceAll('&quot;', '"') || "{}"}
          googleAnalyticsCode={seoData.google_analytics_code}
      />
      <section className="CaseInnerWrapper">
        <div className="CaseInnerContainer">
          <section className="InnerHero">
            <img
              src="/assets/19-55ce4c33.png"
              alt="Structural Health Assessment (SHA) for"
              style={{ width: "100%" }}
            />
            <h2 className="InnerHeroH1 font-face-futur-md-bt">
              Structural Health Assessment (SHA) for{" "}
              <span className="orange">Old Steel Structures</span>{" "}
            </h2>
            <div className="tagsD font-face-futur-HV" id="topGap">
            <Link to="/sectors/steel" className="tags2">Steel</Link>
            <Link to="/services/owners-engineering" className="tags2">Owner’s Engineering</Link> 
            </div>
            <div className="bar">
              <h2 className="InnerHeroH2 font-face-futur-md-bt">
                <div className="lineabc" />
                The Problem at Hand
              </h2>
              <p className="font-face-futur-BK innerPara">
                Old steel structures pose risks of performance degradation and
                potential accidents, necessitating a thorough assessment for
                enhanced structural health, HSE compliance, and a risk-free
                working zone.
              </p>
            </div>
            <div className="why font-face-futur-md-bt">
              <h1>
                Why <span>SIXD?</span>
              </h1>
              <p className="whyPara font-face-futur-LT">
                We provide tailored solutions for old steel structures’ health
                assessment. With a focus on performance enhancement, risk
                mitigation, and adherence to HSE standards, we stands as the
                preferred partner for ensuring the longevity of aging
                structures.
              </p>
            </div>
            <div className="section-inner">
              <div className="lineabc2" />
              <h1 className="section-heading-inner font-face-futur-BK">
                Scope of Work
              </h1>
              <div className="card-container-inner">
                <p className="font-face-futur-LT" />
                <div id="topGap2">
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Compile a comprehensive technical report detailing the
                        findings and providing expert recommendations for
                        structural improvement.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7Vm7UhsxFL3rAGOquGBoWSpeRUyXDrtLB+mSKuYLIF061l262F8AlOmcL8B8AU7BYBcMmxqKrcANOOfYUkZe70O7NqHhznhWbx1dXZ17JYu8yvOIIzMU13VLCwsLK4VCodTtds9lxjITsARZLBYPHMc5RLakioPBYNBA2cnV1dUfmYG8kSllY2PjYH5+vgVQH5AtGlVFlFUAeG9paSm4u7v7LVNKbs2ur6/v4OMRkFHcxq85HNhxjgC0rCuQ9vGpTWMemcHGgPSfnp4Oe73er1DbL2jnIenqMoA+YVke07AGa9il96+z4wQA2ej3+03f94O4vgB9FLJngvay2nPBphHtcnFx8cYEqibk5C7q3sb15SJlpNmSWc6x0L9N7YulJGo2wS47+B0aZcOTD3usmyDDDKF3AtS2l8eenRiQLj7HSXap2nB7a6FJPbbFj7bpyvgia3rb89izEwP0TE+UZpebm5u71Ko5aUg6qP8KrbWjKsP2zAVjru2ouSZ4Fpz4w9To4+Pje2jzZxAE/ajJbm9ve+DQJvr56FeW8S3/BpD7qPclRlB3vry83EXyk+pXmpub67M83DZKszeh7bOmG+4K2tJOgzSGoNCucTiPZNz+KT7mWpU0sDj5A91Bxu2JeQ+aOpUZCBlGRvZtsoSv5wTYCWyx1EVt4sfVtZintsmL1DztVHIKGWZtbe0CyYYBtA2GKHPOpL6JPAstcjs+YpCajFY9BI18C5MeQzsrYg/SxY8HdwhMFfsYq4o5qpeXl6mxg5VT4NbThuh1DNA1pnmaFfFHCuvUib/QB5c2zbEeHh6241giN1gDdJ2aMLeLBw/kfxHliVhmeD5NTSf39/erHCvtAIZlTjIKTQOffQCpA8QZ0q5iD9qzp8JC5ic8H8rrWTQ5NdgQ6FXTEynQ5FuzKe3SikUYa4T6jkkSdVnza7lcLoFXc0VklCjXHUVdiWAN0J4ZpMSJcr0t1aeatuUx16FYsEkHzNcJaoz8mhbOQZPWBwZcu8uDaR4+7kZSnzSnUBWDX5VTOMvCr2GhU+AY4NqW4dZ5+Ko0G8kDlsJtVPxaM0BXZMSvO5JRtra23tEpmHzLsJNOwYYlrJ2C0rK5clcyCiK4bZ2mFsm3iOiatv2tnQKpCoCtB06duFBoZHUKmTzYS0sUWF99K/KfxfB4flT9BFjt99kxa2SVV1SwcyxKQbz1RrWbAAvuo136TCuP0mHUJM8H8kgFOzVV7Iev/LFgO51OEIqsStopIG19x08TsMFe2CnI6AZciXPvkYGMEVmdYjBujw5SPJmRkA2MrFVEltkpaOHrYBZ75pZjwWPXoWd1CuqmMOqIVxWxsGfTLpHd0+UcK6tTyPOK6ErCSwzdqSrj4t4i3wi/zOQNwqd5n61oe9ZlBK2BmelpQWqZ+pk+6s1qbALYJWICL8t2x8nUz/R8fsfTER/rAnXFHj7Vqxvsd9xgP19fX8/kz5CZ/ltDoXnwi+tMJ2ug8iovJX8BiSbQRfeSCm8AAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7Vm7UhsxFL3rAGOquGBoWSpeRUyXDrtLB+mSKuYLIF061l262F8AlOmcL8B8AU7BYBcMmxqKrcANOOfYUkZe70O7NqHhznhWbx1dXZ17JYu8yvOIIzMU13VLCwsLK4VCodTtds9lxjITsARZLBYPHMc5RLakioPBYNBA2cnV1dUfmYG8kSllY2PjYH5+vgVQH5AtGlVFlFUAeG9paSm4u7v7LVNKbs2ur6/v4OMRkFHcxq85HNhxjgC0rCuQ9vGpTWMemcHGgPSfnp4Oe73er1DbL2jnIenqMoA+YVke07AGa9il96+z4wQA2ej3+03f94O4vgB9FLJngvay2nPBphHtcnFx8cYEqibk5C7q3sb15SJlpNmSWc6x0L9N7YulJGo2wS47+B0aZcOTD3usmyDDDKF3AtS2l8eenRiQLj7HSXap2nB7a6FJPbbFj7bpyvgia3rb89izEwP0TE+UZpebm5u71Ko5aUg6qP8KrbWjKsP2zAVjru2ouSZ4Fpz4w9To4+Pje2jzZxAE/ajJbm9ve+DQJvr56FeW8S3/BpD7qPclRlB3vry83EXyk+pXmpub67M83DZKszeh7bOmG+4K2tJOgzSGoNCucTiPZNz+KT7mWpU0sDj5A91Bxu2JeQ+aOpUZCBlGRvZtsoSv5wTYCWyx1EVt4sfVtZintsmL1DztVHIKGWZtbe0CyYYBtA2GKHPOpL6JPAstcjs+YpCajFY9BI18C5MeQzsrYg/SxY8HdwhMFfsYq4o5qpeXl6mxg5VT4NbThuh1DNA1pnmaFfFHCuvUib/QB5c2zbEeHh6241giN1gDdJ2aMLeLBw/kfxHliVhmeD5NTSf39/erHCvtAIZlTjIKTQOffQCpA8QZ0q5iD9qzp8JC5ic8H8rrWTQ5NdgQ6FXTEynQ5FuzKe3SikUYa4T6jkkSdVnza7lcLoFXc0VklCjXHUVdiWAN0J4ZpMSJcr0t1aeatuUx16FYsEkHzNcJaoz8mhbOQZPWBwZcu8uDaR4+7kZSnzSnUBWDX5VTOMvCr2GhU+AY4NqW4dZ5+Ko0G8kDlsJtVPxaM0BXZMSvO5JRtra23tEpmHzLsJNOwYYlrJ2C0rK5clcyCiK4bZ2mFsm3iOiatv2tnQKpCoCtB06duFBoZHUKmTzYS0sUWF99K/KfxfB4flT9BFjt99kxa2SVV1SwcyxKQbz1RrWbAAvuo136TCuP0mHUJM8H8kgFOzVV7Iev/LFgO51OEIqsStopIG19x08TsMFe2CnI6AZciXPvkYGMEVmdYjBujw5SPJmRkA2MrFVEltkpaOHrYBZ75pZjwWPXoWd1CuqmMOqIVxWxsGfTLpHd0+UcK6tTyPOK6ErCSwzdqSrj4t4i3wi/zOQNwqd5n61oe9ZlBK2BmelpQWqZ+pk+6s1qbALYJWICL8t2x8nUz/R8fsfTER/rAnXFHj7Vqxvsd9xgP19fX8/kz5CZ/ltDoXnwi+tMJ2ug8iovJX8BiSbQRfeSCm8AAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Conduct a comprehensive survey to visually assess the
                        condition of old steel structures.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Perform in-depth evaluations to determine the current
                        state of structural components.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Utilize advanced PAUT techniques to inspect and analyze
                        the integrity of critical structural elements.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALpSURBVHgB7Zg9ktowFMcfhGKhiTNDH1MBQxHnBPgIyQni7dJt9gRhT5DNCUJOEHKC9Zbp2IqPJk4PM04DDQP5P0YiipDB2ArLzvCf0cj6QPrN03v6gOgsuyrQEdRsNl+uVitXlpfLZTwejx/oAP030Far9WqxWATFYjFA0dHbAR4hCwuFQmcwGPzaN551UNd1nXK5/BGfH1L+JAboi32dSmRRjUbDRXaH5CrVIVIPFtwsNaz4CZknijGlkDVQCQkIl/4CBvqyoh9bW0JGSD6lkE2LbiBhvc5wOLzROzAk+6QoRkh+Gv9kFcmCBIBLFiDZx3mX0OtzB5O25BEmr1FGSPR7h35dfMbz+bwWRdHGf21YtK1Y85LyQ7Kci4uLK7U9NygGDyQEljzUJm9ngJTyySYoiQjGadM3tG3cAJv/25SQ63FQ55NlUEcMbAL9KT9KpdIXDhTaAQnXCZA+y7IaVFaiPklwhXtM3BVFDyfWnYQ1QaL/16SxbIDGYiKHzLCXOmy9Xr9KgkT9c1mPyP9tEzQSE3hJHXRYXFRuTZBCvshj29tTKEB93Qd1WGQbQPSPEYBv9OUGuAzOUK236qOVSqW9qx1Rfw2QGiBfz2az2mg0+q62C791+Rt5T23LdTLlObsNY7m044TLbFGbkOwysPQ39b6g98l0e7JtSYZEgEnfvIVLbG1TBy/9AWc3+6uPiXum95F4RwUYi18C6yDknUEEHeUCzXjBkO+jdeL9VljPVX8jLHlNCUoNmhUyhULA3+gXGsoCmgNSPvB8DhTl9OoLwId9gKlBcyz3zrP7UBWeAiTtAj0lSEoCPTVIMoFqk0enAMkyHaEdkUd0IpCsf0A9z3Pkecsb8KlAEh14KXksSNaWj+IM5gcZb84RX4alVR8T0gjK7xn5VGBYpD4AHfX5emxI1jO9Yjqd/qhWq+unhQBsKLfuGJDvjw1JJlDWZDK5BywvvTyb+X3T5X9F+AlMZ5111pb+APBNDfd18bgzAAAAAElFTkSuQmCC"
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALpSURBVHgB7Zg9ktowFMcfhGKhiTNDH1MBQxHnBPgIyQni7dJt9gRhT5DNCUJOEHKC9Zbp2IqPJk4PM04DDQP5P0YiipDB2ArLzvCf0cj6QPrN03v6gOgsuyrQEdRsNl+uVitXlpfLZTwejx/oAP030Far9WqxWATFYjFA0dHbAR4hCwuFQmcwGPzaN551UNd1nXK5/BGfH1L+JAboi32dSmRRjUbDRXaH5CrVIVIPFtwsNaz4CZknijGlkDVQCQkIl/4CBvqyoh9bW0JGSD6lkE2LbiBhvc5wOLzROzAk+6QoRkh+Gv9kFcmCBIBLFiDZx3mX0OtzB5O25BEmr1FGSPR7h35dfMbz+bwWRdHGf21YtK1Y85LyQ7Kci4uLK7U9NygGDyQEljzUJm9ngJTyySYoiQjGadM3tG3cAJv/25SQ63FQ55NlUEcMbAL9KT9KpdIXDhTaAQnXCZA+y7IaVFaiPklwhXtM3BVFDyfWnYQ1QaL/16SxbIDGYiKHzLCXOmy9Xr9KgkT9c1mPyP9tEzQSE3hJHXRYXFRuTZBCvshj29tTKEB93Qd1WGQbQPSPEYBv9OUGuAzOUK236qOVSqW9qx1Rfw2QGiBfz2az2mg0+q62C791+Rt5T23LdTLlObsNY7m044TLbFGbkOwysPQ39b6g98l0e7JtSYZEgEnfvIVLbG1TBy/9AWc3+6uPiXum95F4RwUYi18C6yDknUEEHeUCzXjBkO+jdeL9VljPVX8jLHlNCUoNmhUyhULA3+gXGsoCmgNSPvB8DhTl9OoLwId9gKlBcyz3zrP7UBWeAiTtAj0lSEoCPTVIMoFqk0enAMkyHaEdkUd0IpCsf0A9z3Pkecsb8KlAEh14KXksSNaWj+IM5gcZb84RX4alVR8T0gjK7xn5VGBYpD4AHfX5emxI1jO9Yjqd/qhWq+unhQBsKLfuGJDvjw1JJlDWZDK5BywvvTyb+X3T5X9F+AlMZ5111pb+APBNDfd18bgzAAAAAElFTkSuQmCC"
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Conduct a detailed comparison of design specifications
                        with the actual thickness of steel components.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="howwas">
              <div className="how-cnt">
                <span className="deco2" />
                <h1 className="font-face-futur-BK howh1">
                  How was the problem tackled
                </h1>
                <ul className="font-face-futur-LT howp">
                  <li>
                    We adopted comprehensive owners engineering strategy,
                    starting with a meticulous walk-down survey for visual
                    inspections.
                    <br /> <br />
                    Phased Array Ultrasonic Testing (PAUT) techniques were then
                    employed for a detailed assessment of critical components,
                    providing high-resolution insights. The process further
                    included a precision analysis of design versus actual
                    thickness, utilizing sophisticated technologies.
                    <br /> <br />
                    This integrated approach, combining traditional and advanced
                    methods, facilitated a nuanced understanding of structural
                    health, guiding the formulation of targeted recommendations
                    for enhancements.
                  </li>
                </ul>
              </div>
              <div className="how-img">
                <img src="/assets/t17-ebffaa01.png" />
              </div>
            </div>
            <div className="page">
              <img src="/assets/17-74bdeb2a.png" className="page" />
            </div>
            <div className="arch">
              <div className="fxm">
                <span className="deco3" />
                <h1 className="font-face-futur-md-bt">
                  The <span className="orange">Difference</span> Achieved
                </h1>
              </div>
              <div className="side-data">
                <div className="side-image">
                  <img src="/assets/i1-a28f622a.gif" alt="Animated Frame" />
                </div>
                <div className="side-col">
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">
                        Enhanced Structural Integrity
                      </h1>
                      <p className="font-face-futur-LT">
                        Utilizing advanced assessment techniques ensured an
                        in-depth understanding of structural conditions,
                        contributing to enhanced integrity.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Precise Analysis</h1>
                      <p className="font-face-futur-LT">
                        The comparison of design specifications with actual
                        thickness provided precise insights, enabling accurate
                        recommendations.
                      </p>
                    </div>
                  </div>
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Cost Effective</h1>
                      <p className="font-face-futur-LT">
                        Targeted recommendations resulted in cost-effective
                        measures, optimizing the performance of old steel
                        structures
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Improved Safety</h1>
                      <p className="font-face-futur-LT">
                        The assessment focused on risk mitigation, contributing
                        to improved safety standards and accident prevention.
                      </p>
                    </div>
                  </div>
                  <p className="p-side font-face-futur-LT">
                    Our Owner's Engineering expertise is instrumental in
                    enhancing the structural health of old steel plants. The
                    combination of advanced assessment techniques and a
                    commitment to safety ensures the longevity and safety of
                    aging infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div className="sharemy">
              <div className="sharex">
                <h1 className="font-face-futur-md-bt">Share this Case Study</h1>
                <div className="shareb">
                  <div className="share-50">
                    <div className="shareby font-face-futur-md-bt">
                      Share via
                    </div>
                    <ShareIcons />
                  </div>
                </div>
              </div>
            </div>
            <div className="innnercards">
              <h1 className="font-face-futur-BK">View more</h1>
              <div className="wrappermax">
                <Link to="/inner/3">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Precise Revamping and Modernization for Offshore Platforms{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Oil &amp; Gas</p>
                      <p>As-built Engineering</p>
                    </div>
                    <img src="/assets/id1c2-6328b31a.png" />
                  </div>
                </Link>
                <Link to="/inner/17">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      End-to-End Owner’s Engineering Solutions Form Power Plant
                      Excellence{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Owner’s Engineering</p>
                    </div>
                    <img src="/assets/id4c2-972761eb.png" />
                  </div>
                </Link>
                <Link to="/inner/18">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Comprehensive Engineering Solutions To Revolutionize Solar
                      PV Plants{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Power</p>
                      <p>Owner’s Engineering</p>
                    </div>
                    <img src="/assets/id4c3-02851a0a.png" />
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <SliderMe />
    </>
  );
};

export default Inner19;
