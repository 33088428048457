import React from "react";
import SliderMe from "../components/SliderMe";
import ShareIcons from "../components/ShareIcons";
import { Link } from "react-router-dom";
import useSeoData from "../hooks/useSeoData";
import MetaTags from "../components/MetaTags";

const Inner12 = () => {
  const { seoData, loading, error } = useSeoData('/inner/12');
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading SEO data.</div>;
  return (
    <>
      <MetaTags
          title={seoData.meta_title || "Default Title"} 
          description={seoData.meta_description || "Default Description"} 
          robots={seoData.meta_robots || "Default Robots"} 
          canonical={seoData.canonical_link || "https://sixdindia.com/"} 
          schema={seoData.schema_code?.replaceAll('&quot;', '"') || "{}"}
          googleAnalyticsCode={seoData.google_analytics_code}
      />
      <section className="CaseInnerWrapper">
        <div className="CaseInnerContainer">
          <section className="InnerHero">
            <img
              src="/assets/16-232c547d.png"
              alt="Alignment Inspection and Structural Integrity Solutions for"
              style={{ width: "100%" }}
            />
            <h2 className="InnerHeroH1 font-face-futur-md-bt">
              Alignment Inspection and Structural Integrity Solutions for{" "}
              <span className="orange"> Overhead Cranes</span>{" "}
            </h2>
            <div className="tagsD font-face-futur-HV" id="topGap">
            <Link to="/sectors" className="tags2">Others</Link>
            <Link to="/services/dimension-control" className="tags2">Dimension Control</Link>
            </div>
            <div className="bar">
              <h2 className="InnerHeroH2 font-face-futur-md-bt">
                <div className="lineabc" />
                The Problem at Hand
              </h2>
              <p className="font-face-futur-BK innerPara">
                Frequent crane failures due to misalignments and structural
                issues pose significant safety and financial risks, demanding a
                proactive approach to prevent breakdowns and optimize
                operational efficiency.
              </p>
            </div>
            <div className="why font-face-futur-md-bt">
              <h1>
                Why <span>SIXD?</span>
              </h1>
              <p className="whyPara font-face-futur-LT">
                Equipped with intrinsic and flame proof-certified systems,
                experts with international exposure work in offshore conditions
                and have experience with complete offshore platforms as built
                through survey and modelling with required deliveries
                internationally.
              </p>
            </div>
            <div className="section-inner">
              <div className="lineabc2" />
              <h1 className="section-heading-inner font-face-futur-BK">
                Scope of Work
              </h1>
              <div className="card-container-inner">
                <p className="font-face-futur-LT" />
                <div id="topGap2">
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMLSURBVHgB7VixchoxEF2IZ2xXuRkXLnOd7SpXpgufAF06yBfE/gJIly7kCwxfYPIFvpTpSMEAlZUuDTPnCiiAvCWSI4QEJw7GLu7NaKTTaVdv91bS6ohy5MjhjUKaQZeXl+9RlVBC1bdYLJJisdjs9/u/U8iHGP+pUCgEWrdAiQeDwY9t8ke0nVwLykPzHfpoPp9zf4W2ow6Dao45BAy4Hg6H313Cr8hNsA4iLWU96gTVH5REFSj/MhqNhrQF5+fnAtU7lIkmr/QGKB/Ozs4S6Pppky84CIYQfFDkZrNZYzqdtoUQCe0RmKcK/U1FGEaXbJ+/aBOG4K3WLuFTfNs3QQYItUGsrHU1bOPWSEZRxFaVuA0FrV6v94sOCOm5DrfZIWEYBuaYtYUzmUw4RkiS7Dp0LxcVxkUYE9AWcMiwrg0rOUZZevT09PQ1/YtZN0lTudnHlp6cnNyx1XIMpQGPg2ExnFAxQwcGPG7SUyRPgOBXRdAXLMfy5AlvkpioRhkA+bIt7jZhjSQs5U8h5Nazsmh4a6LsCGTc6XiQtRiPx4+mgDMQ2FozdvT9MyNC8zi1zafgXDg2AfYyNvWYsiGxeesQ+3AOE08xeXFxwVtLRC8HAnvqDYfBMibl6XFNLwxYA21U8ZMnQfRWyxtLshay6ChRRmCeLifN2nOA50h/h8IJ8edln6lA32YwsMaZiu1dRqxsQdBb0zKvte3J+8R5DniRhFcFGRnKDhBp7kU6vD2Je02DMgAh1CBPeJPkLJ1jlQOc/NCFgWU9xtPiiHaAnMh7sl2x0ZNqWzg08FXeqrbtXF8jKReHWL7EXdk39/OF/HGgLmPClmhYPYnYacom5373V1dXb+gAYAeA4J06RFyLyplPgtg9/T9d+ATgG51YEcbPgzTbCZOBsVXj0sYHA3tQ9XWgy/o3xLlwjo+PK8gd62jymR7Yrg2YNET1kbYABJd6XJctvjpzMuGSd5LsdrscGzeIGf5HU0V8RvpC4usFwqJDKcDXWcgnhif5H1CMuo11EFOOHDn2i79LWnDjgOZDWQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Evaluate straightness and elevation of rails/tracks.
                        <br /> <br />
                        Conduct span verifications and rail-to-rail elevation
                        assessments.
                        <br /> <br />
                        Positional inspection of the end truck, providing
                        real-time guidance for rectifications.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKTSURBVHgB7VhNctowGP1szLBgUd+gyoaBYYF7gnKE3qD0BvQEpSdobpBwgjYnqHsDdwW7qieIuwJmAPd9VKaOI//GJiHxmxG2pWfpIev7kYieO4bD4WgwGNz2+/2rLC5zUH6hvKWSMKkkdrvdO1xswzAmWVzmoAjcjqkkSgs9NRqhVcPIIigDmKCIWJNQ646CIHDT+gBvrHgSFxltQ52P9m/L5XKe2geli7zKYywV4XKxWHxMakwUCpGfIHJ2IBmGj3/uUQ1A3w76tvke1xlm9rOWp6t0HMfebDa36lGuVqs3UkqfagAmhJfQVx4WxcdYF7qxtMa0Xq9H4T06mdYlkoEZlOGXA+x2uy10vEyr3+/3f6hmYIzjRLRaLVvHafxo1TgboRZnQVgjLu7tIi8WfU85+wmM50caD4b1HVnZvfdMDDamgiIZYfaUl182e1LvCavT6czhjtjqRKRdFIlI7KgpfbDU9lhf1xQJsxxsoO/G8jyPRd6Js5H4ngtJ0SQEPuWM8mOO/tx4ZWP1VaMRWjUs/un1eiPTNI+uhlMveiTA6kcw5iBax77XQuV7CLumJwJM2GW8DhonFlUATrKpZli8V8FAv6OVmH4H/+xL3k6KOPQcmGL8n+EDdPjYoniHGY072PgaSYBL/yKIyME9RJjtdnuTxWOROodf+tOr5OKCToTGj1aN8xaKXPO4oYPVvaK6RUSCTSJHV9ntdiUuh50h55pI015TTRBC2Ejej04euaf2oCPXSQlQ60kJqZ1C4ZOSEJhJdvpTOgE4s4fID0nteU7zOBfQ7Y8E/Xf2bkY3Y3WVdP80j5+1WX0l4KWBGQ+4ZHFD3kNygsaPVo0XIdRFkWofngrmcPaEQJKZPZ09/gKLshKf+6JflQAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Provide on-site supervision to guide corrective measures
                        based on inspection outcomes.
                        <br /> <br />
                        Verify and ensure the effectiveness of corrections
                        through a final cross-check of positions
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALoSURBVHgB7VhLctpAEG0RFixZeJ/JgipgJZ8gygnsI/gGrpzA+Ai+AdzAN4hyAosF/0XGe6rCioINpJ/Uo0yEwCoZhKj4VYkZtXpmmu7pzwzRB/5TOElCs9n8ys2d4zget3V5ioLebrea2+54PO7ZHz7ZLyzkAwvY5celSMAaFYs6r634ub26uqL5fP7TfHAsIcHwKyQ6zmKz2Tzzv1ugv2dST7ROzNcRGua4E1qXGy3zdYTmc+MLL6ymKNJiV/igIDxK+D3WbChsNZbYcW5MnxlcZnilA+A/FgqLH+Z9FFq4bYSlx3QfnVar1RGab3iZhrEKpjY0mSNWGH+75SYUtGILJ109Go0OCnlKsNCa/loi9o9YUDEDyWbOhVqt1pdFtNHKPmBrSddP+aylVYZQpSMiCALs5y9ZeCeTyRM3T5QR7xEU4cOzNLMXcDY4CiIK5URuQWUvfcvI+0jvRIUuBGUVNPR2xHFDKJ2gHF/vKQr6gG/oO3sUm56D7o8E2U/bZ0qpOoeke8RgO+blhYRIJa+6Uqk87xWUonzrJWh43xGUhXyR3EzHBNI28v1gMHjdKyiYWEOBTUsLQe12+4b5lIwJ7P2UF0g2mGu5XPa01v/MtyMoCxWwmd8MO8znGk0Oh8NrOjEuJjylmV6hLpVXnSxgz4U0Z1KmfgQajUZ/NpsFdGa8ZXpdrVZ/Uwmwo1FU4VmcqWhctDPV5Uhh085a9QNpzoT46NsEqfozFcSnQibTHztF5kGaRpEOv9uE1Wp19vCU5vULc8wtEy7a6z0uXrfow4nY7NfJSuYcOKhR5H2uOV0qAdKcKb4Loqgo8akESHMmfYzj7bFhmz7ch8c4+5wC9iWZcRiVZaBcEQKFxFj72tEsiFz/gHPSdDrtJweYkyd3cSVIyfPVqRDnRtd16+v1+oUyajQcHB0E3SIKltj0uInjRVGH6oxjcWL0iqqqUqsNNr1HkWY/7wyItNhH/i9DIvhAXvwBYuFJUcafED0AAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALoSURBVHgB7VhLctpAEG0RFixZeJ/JgipgJZ8gygnsI/gGrpzA+Ai+AdzAN4hyAosF/0XGe6rCioINpJ/Uo0yEwCoZhKj4VYkZtXpmmu7pzwzRB/5TOElCs9n8ys2d4zget3V5ioLebrea2+54PO7ZHz7ZLyzkAwvY5celSMAaFYs6r634ub26uqL5fP7TfHAsIcHwKyQ6zmKz2Tzzv1ugv2dST7ROzNcRGua4E1qXGy3zdYTmc+MLL6ymKNJiV/igIDxK+D3WbChsNZbYcW5MnxlcZnilA+A/FgqLH+Z9FFq4bYSlx3QfnVar1RGab3iZhrEKpjY0mSNWGH+75SYUtGILJ109Go0OCnlKsNCa/loi9o9YUDEDyWbOhVqt1pdFtNHKPmBrSddP+aylVYZQpSMiCALs5y9ZeCeTyRM3T5QR7xEU4cOzNLMXcDY4CiIK5URuQWUvfcvI+0jvRIUuBGUVNPR2xHFDKJ2gHF/vKQr6gG/oO3sUm56D7o8E2U/bZ0qpOoeke8RgO+blhYRIJa+6Uqk87xWUonzrJWh43xGUhXyR3EzHBNI28v1gMHjdKyiYWEOBTUsLQe12+4b5lIwJ7P2UF0g2mGu5XPa01v/MtyMoCxWwmd8MO8znGk0Oh8NrOjEuJjylmV6hLpVXnSxgz4U0Z1KmfgQajUZ/NpsFdGa8ZXpdrVZ/Uwmwo1FU4VmcqWhctDPV5Uhh085a9QNpzoT46NsEqfozFcSnQibTHztF5kGaRpEOv9uE1Wp19vCU5vULc8wtEy7a6z0uXrfow4nY7NfJSuYcOKhR5H2uOV0qAdKcKb4Loqgo8akESHMmfYzj7bFhmz7ch8c4+5wC9iWZcRiVZaBcEQKFxFj72tEsiFz/gHPSdDrtJweYkyd3cSVIyfPVqRDnRtd16+v1+oUyajQcHB0E3SIKltj0uInjRVGH6oxjcWL0iqqqUqsNNr1HkWY/7wyItNhH/i9DIvhAXvwBYuFJUcafED0AAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Perform geometrical inspections of columns, assessing
                        axial straightness and verticality.
                        <br /> <br />
                        Evaluate elevation and provide shimming assistance for
                        geometrical corrections.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK9SURBVHgB7Vg9jtpQEB4gEjRILqCiWFMgfpqQE4ScAHIACDnBpky3e4NsThDDCbZHaJ0TrFOBoGEl6CgcUQASP/kG3tuwBCf2e5Y2K/FJz+M3Htuf583MGyA644znRYRCQqFQMCHuMOx+v/+RQsIrCg8XkUjEhKxQiIjSf44zQV3EKATk8/kq4u+ziEEjlUqZ6XTamU6nP0kT2lmM7L0CsesTl9z1el0ZDAY/SANaS8ylRZKr1+vU6XTIsizWs8qIRqM39JwoFouXGNtGo7E9xHg83rJejAvSgJYHwcVgmclknuh5nkwmpU2WNKCbxQ986Ha7NJvNHpWTyeRxvlgsHNKAVpKUy2VjuVze49Rkr3EcMrF2u72T8J6lu+1pZzHHIcTJZFitVm+Gw6GWB8PYi2tCMhFXDBOjHIvFqkKvDK0YzOVyZfrdHNR6vd47jPc4t1iBEvSJNKFFEB7i5d3FGog9SH08Hm/R3pNGqVSqkgaUCYoi3RTT1uE1x3FckN7F5Waz0fKiMkEQkJ4ZIVPt4+uJROIrS3xEBR/zlhShTFDGF4hen7rOXoSwxbRJilAiCI98EJ0Le6/lZSfJw7ZmmqZBClAiKGMPBOy/2YH8d9p70cCSX5ICAhM8LC0ebdYxboWtUrIEJuhVWrygW3ICETwsLdzJ+Gml0Cy8hi0TVCo5gfZiEPoC8eQlHIcgfQvpgMCOiPz5iYaVt0HzyL4iYtMXAu3FeHgNL5fZufMm1zkmw3os/x/3QO+C+I20o33J8U3QtwdFabFwOkLsZYWOSXJcSU/xnHcRV3jWmc/nrdFo5HKxxtyGjQtdlnUUJrC899zC40XfSBG4/04848rvPb6SRGxVXF78lhYvBC45frO4yQe/pcULKiXnnwS5rffqWoJCpcvx5UEOdtr/rWaTJrjL4edxWaIzzngB+AXpqjUNWJ4eLAAAAABJRU5ErkJggg=="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK9SURBVHgB7Vg9jtpQEB4gEjRILqCiWFMgfpqQE4ScAHIACDnBpky3e4NsThDDCbZHaJ0TrFOBoGEl6CgcUQASP/kG3tuwBCf2e5Y2K/FJz+M3Htuf583MGyA644znRYRCQqFQMCHuMOx+v/+RQsIrCg8XkUjEhKxQiIjSf44zQV3EKATk8/kq4u+ziEEjlUqZ6XTamU6nP0kT2lmM7L0CsesTl9z1el0ZDAY/SANaS8ylRZKr1+vU6XTIsizWs8qIRqM39JwoFouXGNtGo7E9xHg83rJejAvSgJYHwcVgmclknuh5nkwmpU2WNKCbxQ986Ha7NJvNHpWTyeRxvlgsHNKAVpKUy2VjuVze49Rkr3EcMrF2u72T8J6lu+1pZzHHIcTJZFitVm+Gw6GWB8PYi2tCMhFXDBOjHIvFqkKvDK0YzOVyZfrdHNR6vd47jPc4t1iBEvSJNKFFEB7i5d3FGog9SH08Hm/R3pNGqVSqkgaUCYoi3RTT1uE1x3FckN7F5Waz0fKiMkEQkJ4ZIVPt4+uJROIrS3xEBR/zlhShTFDGF4hen7rOXoSwxbRJilAiCI98EJ0Le6/lZSfJw7ZmmqZBClAiKGMPBOy/2YH8d9p70cCSX5ICAhM8LC0ebdYxboWtUrIEJuhVWrygW3ICETwsLdzJ+Gml0Cy8hi0TVCo5gfZiEPoC8eQlHIcgfQvpgMCOiPz5iYaVt0HzyL4iYtMXAu3FeHgNL5fZufMm1zkmw3os/x/3QO+C+I20o33J8U3QtwdFabFwOkLsZYWOSXJcSU/xnHcRV3jWmc/nrdFo5HKxxtyGjQtdlnUUJrC899zC40XfSBG4/04848rvPb6SRGxVXF78lhYvBC45frO4yQe/pcULKiXnnwS5rffqWoJCpcvx5UEOdtr/rWaTJrjL4edxWaIzzngB+AXpqjUNWJ4eLAAAAABJRU5ErkJggg=="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Examine sagging and geometrical distortions in the
                        bridge.
                        <br /> <br />
                        Analyze tension, compression, and bending forces.
                        <br /> <br />
                        Inspect straightness and leveling of the bridge rail.
                        <br /> <br />
                        Assess the positional integrity of the trolley.
                        <br /> <br />
                        Provide corrective analysis and inputs for bridge truck
                        components.
                      </p>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="card-content-inner">
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        alt="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANgSURBVHgB7Vg7chpBEB0+Kn2idUm5l0ASkgKtTyCUObN0AqMTCJ9A+AS2MmdGJ7BuoHXoyDjRL/E6c6IqHFBAAPg9mMHDMguLBbO2ile19DCf3Tc90z09LcQCCywwE6TGNbqu66yurr7udDqFVCrldLvdGuTl7e3thbCMSKI7OzunEGU8TrgNhAOQP7q/v/8mLCFtqszn82cQ78UfkgEeH9qs8Q+km8lk/K2trX1hCSMa3dvb24e2qvJvAO2dYKl91b69vX2aTqc5iZ5mm83miyAIamLOyIQrNjY2PkDk5V8PJIeW9+Hh4cv6+jr36kvuW2j2J+vEnJE11BX4A21VQPKHadDd3d059nAZRQdkPb2Nextjj0zjHmOMQ0Q9z3NarZYjX1qdMJbtBTxuqL4YJq+AeoojTMa7ubl5I6bAkDFVq1V9r7kTxjrCTKYM4ZseZYxASRpsbIwYE2Z7BVGgoWCJcqZB+IiLj35nGf3K6PdWxIAc9wlFarzWaDRycQ1xxD3hw35vBnBBIP3O9DGIK/Uf/SoiJjChQGqccJaWlty4Y40OHwS/iv6sey4IoiL6rsqDayoKuezTaFMBEz0AWV+OP9Rd3ziYrJ4vOMbLqDUulSv6J5QyBvG3JB8D48nEJYJV5kCGFjywfmkMvtSENZJEdlyj9HcXDE5WVlYckA9EQsjG6QSSr2QxEAkhPamD3PwVPpubm55ICBOJ6sC57oiEMBXRJLEgOmskTfSMYSGe55M6WiNKX8zYQR2fBMoF0b/yBPAuH9knarw1osvLyzySS6HqQBVAuogb71UUWStEGXsimFE+uHcEI8R7Jo9phpKXss3D4XJqekesk2kGKErJGOJQb2BcAXGs4mBotgStnofj1LlrVJ5sLsskEdWP0ZgsOmtrawfhdhtLn9PIRN7DsOSD22673XbD7f+MH63X664qa3erAWwQ9VWB8W1Up2w2qy/3yDV97kSlsfgsc4+a0kDMzgh5iwAC0/XEitXTUKSjZ2alCgOroO5SZgg9pJBKWt8T0zus7FFo6LO+7HTu8KskWoHUSRajLnvWjInXGjp3por0enUP44EwLtVjy+H3IPcrl/Zkd3eXS84kRC1OEsIqUR3X19eTcltDeDrxKE6JX+J/AfNNMueUGH4DWfJu/1xlAJAAAAAASUVORK5CYII="
                        className="card-icon-inner"
                      />
                      <p className="card-paragraph-inner font-face-futur-LT innP">
                        Conduct visual inspections and analyze available data to
                        assess runway conditions, wheel flange integrity, power
                        consumption, rail and wheel conditions, and other
                        critical parameters.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="howwas">
              <div className="how-cnt">
                <span className="deco2" />
                <h1 className="font-face-futur-BK howh1">
                  How was the problem tackled
                </h1>
                <ul className="font-face-futur-LT howp">
                  <li>
                    The challenge was effectively addressed through our
                    innovative Crane Failure Analysis and Prevention Method,
                    deploying dimension analysis software for precise 3D
                    measurements and alignments.
                    <br /> <br />
                    This approach minimized downtime, enhanced operational
                    efficiency, and provided proactive insights for preventive
                    measures, ensuring the longevity and reliability of overhead
                    cranes.
                    <br /> <br />
                    The real-time capabilities of the software expedited
                    corrective actions, surpassing traditional methods in
                    accuracy and speed, contributing to sustained operational
                    efficiency.
                  </li>
                </ul>
              </div>
              <div className="how-img">
                <img src="/assets/t16-e72cdd34.png" />
              </div>
            </div>
            <div className="page">
              <img src="/assets/p16-fa975904.png" className="page" />
            </div>
            <div className="arch">
              <div className="fxm">
                <span className="deco3" />
                <h1 className="font-face-futur-md-bt">
                  The <span className="orange">Difference</span> Achieved
                </h1>
              </div>
              <div className="side-data">
                <div className="side-image">
                  <img src="/assets/i1-a28f622a.gif" alt="Animated Frame" />
                </div>
                <div className="side-col">
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Enhanced Accuracy</h1>
                      <p className="font-face-futur-LT">
                        Achieved 5 to 10 times higher accuracy compared to
                        conventional methods, ensuring meticulous crane
                        assessments.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Cost Saving</h1>
                      <p className="font-face-futur-LT">
                        Completed project 50% faster, resulting in significant
                        savings in time, manpower, and resources
                      </p>
                    </div>
                  </div>
                  <div className="row-side">
                    <div className="card-main-side">
                      {" "}
                      <span className="deco4" />
                      <h1 className="font-face-futur-BK">Precision</h1>
                      <p className="font-face-futur-LT">
                        Provided fast, comprehensive, and safe solutions,
                        optimizing crane alignment and ensuring long-term
                        operational success.
                      </p>
                    </div>
                    <div className="card-main-side">
                      {" "}
                      <h1 className="font-face-futur-BK" />
                      <p className="font-face-futur-LT" />
                    </div>
                  </div>
                  <p className="p-side font-face-futur-LT">
                    Our methodology not only revolutionized crane alignment
                    inspections but also demonstrated substantial time and cost
                    savings, along with unparalleled accuracy and precision,
                    contributing to the overall efficiency and safety of crane
                    operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="sharemy">
              <div className="sharex">
                <h1 className="font-face-futur-md-bt">Share this Case Study</h1>
                <div className="shareb">
                  <div className="share-50">
                    <div className="shareby font-face-futur-md-bt">
                      Share via
                    </div>
                    <ShareIcons />
                  </div>
                </div>
              </div>
            </div>
            <div className="innnercards">
              <h1 className="font-face-futur-BK">View more</h1>
              <div className="wrappermax">
                <Link to="/inner/8">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Indigenising the alignment of critical diaphragms with
                      NTPC{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Steel</p>
                      <p>Dimension Control - DPM</p>
                    </div>
                    <img src="/assets/id3c1-65ad2531.png" />
                  </div>
                </Link>
                <Link to="/inner/3">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Precise Coke Drum Replacement at an elevation of 106
                      meters with Reliance Industries
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Oil &amp; Gas</p>
                      <p>As-built Engineering</p>
                    </div>
                    <img src="/assets/id4c1-a86d3ef5.png" />
                  </div>
                </Link>
                <Link to="/inner/1">
                  <div className="cardmax">
                    <h1 className="font-face-futur-md-bt">
                      {" "}
                      Enhancing Passenger Comfort with Wipro Limited{" "}
                    </h1>
                    <div className="tagsmax font-face-futur-md-bt">
                      <p>Others</p>
                      <p>Re-engineering &amp; Supply</p>
                    </div>
                    <img src="/assets/id3c3-775b0b1f.png" />
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <SliderMe />
    </>
  );
};

export default Inner12;
